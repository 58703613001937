import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Home() {
    const getH1Styles = () => ({
        fontWeight: "bold",
        fontSize: "3rem",
        color: "#fff",
        marginBottom: "15px",
    });


    return (
        <Container
            fluid
            id="page1"
            style={{
                height: 'calc(100vh + 56px)',
                paddingTop: '56px', // 네비게이션 바 아래로 여백 추가
            }}
        >
            <Row
                style={{
                    height: '100%',
                    scrollSnapAlign: 'start',
                    display: 'flex',
                    alignItems: 'stretch',
                    background: 'linear-gradient(to right, rgba(203,246,196), rgba(160,214,171))',
                }}
            >
                {/* 왼쪽 그리드 */}
                <Col
                    xs={4}
                    className="p-0"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRight: '2px solid #ffffff',
                        height: '100%',
                    }}
                >
                    <h1 style={{ fontSize: '4rem', color: 'yellow' }}>Hoddy Portfolio</h1>
                </Col>

                {/* 오른쪽 그리드 */}
                <Col
                    xs={8}
                    className="p-0"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        height: '100%',
                    }}
                >
                    <div style={{ paddingLeft: '30px' }}>
                        <h1 style={getH1Styles()}>안녕하세요</h1>
                        <h1 style={getH1Styles()}>백엔드 개발자</h1>
                        <h1 style={getH1Styles()}>이호성입니다.</h1>
                        <hr style={{ borderColor: 'rgba(255, 255, 255, 1)', borderWidth: '7px', width: '100%' }} />
                        <h4 style={{ color: 'white' }}>방문해 주셔서 감사합니다!</h4>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}
export default Home;