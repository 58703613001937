import React, { useState } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";

function Contact() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "", // 연락처
        message: "",
    });
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);

    // 폼 입력 값을 상태로 업데이트
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // 유효성 검사 (이메일과 연락처 중 하나는 필수)
    const isValid = () => {
        return formData.email || formData.phone; // 이메일 또는 연락처 중 하나는 있어야 함
    };

    // 외부 API로 폼 제출 처리
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValid()) {
            setError("이메일 또는 연락처를 반드시 입력해야 합니다.");
            return;
        }

        const payload = {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            message: formData.message,
          };


        try {

            // API 요청을 보낼 URL을 입력하세요.
            const response = await fetch("https://2hoddy.com/apis/portfolio/message", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload)
                
            });

            if (response.ok) {
                setSubmitted(true);
                setFormData({
                    name: "",
                    email: "",
                    phone: "", // 연락처도 초기화
                    message: "",
                });
                setError(null); // 오류 상태 초기화
            } else {
                throw new Error("서버 오류가 발생했습니다.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            setError("메시지 전송에 실패했습니다. 다시 시도해 주세요.");
        }
    };

    return (
        <Container
            id="page4"
            fluid
            style={{
                height: "100vh",
                scrollSnapAlign: "start",
                background: "linear-gradient(to right, #f39c12, #e74c3c)",
                color: "white",
                paddingTop: "56px",
                paddingBottom: "50px",
                paddingLeft: "20px",
                paddingRight: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                overflowY: "auto",
            }}
        >
            <h2
                style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "30px",
                    fontSize: "2.5rem",
                }}
            >
                Contact Me
            </h2>
            <p style={{ textAlign: "center", fontSize: "1.2rem", marginBottom: "50px" }}>
                궁금한 점이 있으면 언제든지 연락주세요!
                <br />
                아래 양식을 작성해주시면 저의 개인 Slack으로 메세지가 전송됩니다.
                <br />
                빠르게 답변드리겠습니다.
            </p>

            {/* 연락처 폼 */}
            <Form onSubmit={handleSubmit} style={{ maxWidth: "600px", width: "100%", backgroundColor: "#ffffff", padding: "20px", borderRadius: "8px" }}>
                {submitted && (
                    <Alert variant="success">
                        메세지가 전송되었습니다. 빠른 시일 내에 답변드리겠습니다!
                    </Alert>
                )}
                {error && (
                    <Alert variant="danger">
                        {error}
                    </Alert>
                )}

                <Form.Group className="mb-3" controlId="formName">
                    <Form.Label>이름</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="이름을 입력하세요"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label>이메일</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="이메일을 입력하세요"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPhone">
                    <Form.Label>연락처</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="연락처를 입력하세요"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formMessage">
                    <Form.Label>메시지</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="메시지를 입력하세요"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>

                <Button variant="primary" type="submit" style={{ width: "100%" }}>
                    제출하기
                </Button>
            </Form>
        </Container>
    );
}

export default Contact;
