import React from "react";
import { Container } from "react-bootstrap";

function Experience() {
    return (
        // Container의 fluid 속성 사용하여 화면 가득 채우기
        <Container
            id="page3"
            fluid
            style={{
                minHeight: "100vh", // 최소 높이를 100vh로 설정
                background: "linear-gradient(to right, #8e44ad, #3498db)",
                color: "white",
                paddingTop: "56px", // 네비게이션 바를 고려해 상단 패딩 추가
                paddingBottom: "50px", // 페이지 하단 여백 추가
                paddingLeft: "20px", // 왼쪽 여백 추가
                paddingRight: "20px", // 오른쪽 여백 추가
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start", // 페이지가 상단에 맞춰 시작하도록 설정
                scrollSnapAlign: "start", // 스크롤 스냅 설정
            }}
        >
            <h2
                style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "30px",
                    fontSize: "2.5rem",
                }}
            >
                <br />
                업무 경험
            </h2>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "40px",
                    paddingLeft: "10px", // 왼쪽 여백 추가
                    paddingRight: "10px", // 오른쪽 여백 추가
                }}
            >
                {/* 세 번째 경험 */}
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                    {/* 타임라인 점 */}
                    <div
                        style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            marginRight: "20px",
                            flexShrink: 0,
                        }}
                    ></div>
                    {/* 경험 상세 */}
                    <div>
                        <h3 style={{ margin: 0, fontWeight: "bold", fontSize: "1.5rem" }}>
                            빅케어
                        </h3>
                        <p>2022.08 - 2024.10</p>
                        <h4
                            style={{
                                margin: "5px 0",
                                fontWeight: "normal",
                                color: "#f1f1f1",
                                fontSize: "1.2rem",
                            }}
                        >
                            Backend Developer
                        </h4>
                        
                        <h5 style={{
                            fontSize: "1.0rem"
                        }}>
                        Java, SpringBoot, NCP, Firebase, mySQL, JPA(SpringDataJpa)
                        </h5>
                            <br/>
                        <ul style={{ margin: 0, lineHeight: "1.6", fontSize: "1rem" }}>
                            <li>HALO Project</li>
                            - 결제 대행식 결제 시스템 구축
                            <ul>
                                <li>PG - TossPayments</li>
                                <li>- 결제, 취소, 조회, 빌링, 빌링 승인, 웹훅 구현</li>
                            </ul>
                            - HALO 관련 DB 구축
                            <ul>
                                <li>- 운영중 제휴 회원에 연관되는 기업, 업체 스키마 생성 및 연동</li>
                            </ul>
                            - B2B 쿠폰 기능 구축
                            <ul>
                                <li>내부 자체 발급, 검증 기능 구현</li>
                                <li>외부 업체 통신을 통한 외부 발급 기능</li>
                            </ul>
                            - NCP 기능 활용
                            <ul>
                                <li>Cloud Function Trigger 통한 스케줄링 FCM 발송</li>
                            </ul>
                            - Push Device 발송 통합 로직 수정 - OS 별 분리 발송 구현
                            <br />
                            - Exception 처리 변경
                            <ul>
                                <li>공통 에러 처리 코드 - 각 Exception 별 분리 CustomException 변경</li>
                                <li>Exception 발생 시 Telegram Chat 발송</li>
                            </ul>
                            <br />
                            <li>CheckUp(건강검진) Project</li>
                            - Admin V2 고도화
                            <ul>
                                <li>DashBoard - 통계, 병원 및 기업 별 수치 제공 구현</li>
                                <li>내부 스키마 변경</li>
                                <li>DB 스키마 변경으로 인한 검진 프로그램 관련 코드 전체 변경</li>
                            </ul>
                            - CS/운영
                            <ul>
                                <li>데이터 추출 및 고객 데이터 변경</li>
                                <li>신규 병원 데이터 및 프로그램 주기적 변경</li>
                            </ul>
                            <br />
                            <li>LoveBus Project -  진행 중 내부 사정으로 인한 정지</li>
                            - SpringCloudGateWay 도입
                            <ul>
                                <li>Domain별 모듈 분리 / URL 별 각 API 모듈 라우팅</li>
                            </ul>
                            - 로그인 부분 통합
                            <ul>
                                <li>기존 각 소셜 로그인 부분 통합 연동 로그인 변경</li>
                            </ul>
                        </ul>
                    </div>
                </div>

                {/* 두 번째 경험 */}
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <div
                        style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            marginRight: "20px",
                            flexShrink: 0,
                        }}
                    ></div>
                    <div>
                        <h3 style={{ margin: 0, fontWeight: "bold", fontSize: "1.5rem" }}>
                            프래프
                        </h3>
                        <p>2022.04 - 2022.08</p>
                        <h4
                            style={{
                                margin: "5px 0",
                                fontWeight: "normal",
                                color: "#f1f1f1",
                                fontSize: "1.2rem",
                            }}
                        >
                            Backend Developer
                        </h4>
                        <h5 style={{
                            fontSize: "1.0rem"
                        }}>
                        Java, spring, mySQL, myBatis, thymeleaf, jsp
                        </h5>
                        <br/>
                        <ul>
                            <p style={{ margin: 0, lineHeight: "1.6", fontSize: "1rem" }}>

                                <li>메르카도(OneByEat) - 이커머스</li>
                                - NHN KCP 결제 연동
                                <br />
                                - 결제 시 포인트 재확인 및 커스텀 처리
                                <br />
                                - 스케줄러를 통한 쿠폰 발급 및 조회
                                <br />
                            </p>
                        </ul>
                    </div>
                </div>
                {/* 첫 번째 경험 */}
                <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <div
                        style={{
                            width: "20px",
                            height: "20px",
                            backgroundColor: "white",
                            borderRadius: "50%",
                            marginRight: "20px",
                            flexShrink: 0,
                        }}
                    ></div>
                    <div>
                        <h3 style={{ margin: 0, fontWeight: "bold", fontSize: "1.5rem" }}>
                            엘에스 소프트
                        </h3>
                        <p>2021.03 - 2022.03</p>
                        <h4
                            style={{
                                margin: "5px 0",
                                fontWeight: "normal",
                                color: "#f1f1f1",
                                fontSize: "1.2rem",
                            }}
                        >
                            Backend Developer
                        </h4>
                        <h5 style={{
                            fontSize: "1.0rem"
                        }}>
                        Java, Oracle, jsp, Spring, myBatis
                        </h5>
                        <br/>
                        <ul>
                            <p style={{ margin: 0, lineHeight: "1.6", fontSize: "1rem" }}>

                                <li>SKMnS( 파견 )</li>
                                20211.04 ~ 2021.12
                                <br />
                                - Asis - Tobe 작업
                                <br />
                                - 전 기능 QA 및 에러코드 수정
                                <li>공영홈쇼핑( 파견 )</li>
                                2022.01 ~ 2022.03
                                <br />
                                - 이벤트 당첨자 조회, 유입 인원 조회 및 모니터링 데이터 추출
                                <br />
                                - 프로시저 생성 및 변경 등 Data 관련 업무진행

                            </p>
                        </ul>
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Experience;
