import React, { useState } from "react";
import { Container, Form, Button, Modal, Table } from "react-bootstrap";
import axios from "axios";

function Apis() {
    const [si, setSi] = useState("");
    const [gu, setGu] = useState("");
    const [dong, setDong] = useState("");
    const [modalData, setModalData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setModalData(null);

        axios
            .get("https://2hoddy.com/apis/dataApis/weather", {
                params: { si, gu, dong },
            })
            .then((response) => {
                console.log("API 응답 데이터:", response.data); // 응답 데이터 확인
                const { status, message, data } = response.data;

                // 상태가 성공인지 확인
                if (status !== "success") {
                    setError(message || "알 수 없는 오류가 발생했습니다.");
                    setModalData(null);
                    return;
                }

                setModalData(data); // 데이터 저장
                setShowModal(true); // 모달 열기
            })
            .catch((err) => {
                console.error("에러 발생:", err);
                setError("네트워크 오류가 발생했습니다.");
                setModalData(null);
            })
            .finally(() => {
                setLoading(false); // 로딩 종료
            });
    };

    const handleClose = () => setShowModal(false);

    return (
        <Container
            id="page5"
            fluid
            style={{
                height: "100vh",
                scrollSnapAlign: "start",
                background: "linear-gradient(to right, #8e44ad, #3498db)",
                color: "white",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <h1 style={{ marginBottom: "20px" }}>Weather API</h1>
            <p>데이터베이스를 통해 지역의 경도를 조회 후 <br />공공데이터 포털을 통해 날씨 정보를 받아옵니다.</p>
            <Form
                onSubmit={handleSubmit}
                style={{
                    width: "100%",
                    maxWidth: "400px",
                    background: "rgba(255, 255, 255, 0.1)",
                    padding: "20px",
                    borderRadius: "10px",
                }}
            >
                <Form.Group className="mb-3" controlId="formSi">
                    <Form.Label>시</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="해당 지역의 시"
                        value={si}
                        onChange={(e) => setSi(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGu">
                    <Form.Label>구</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="해당 시의 구"
                        value={gu}
                        onChange={(e) => setGu(e.target.value)}
                        required
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formDong">
                    <Form.Label>동(로)</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="해당 구의 동(로)"
                        value={dong}
                        onChange={(e) => setDong(e.target.value)}
                    />
                </Form.Group>

                <Button variant="primary" type="submit" style={{ width: "100%" }}>
                    조회
                </Button>
            </Form>

            {loading && <p style={{ marginTop: "20px" }}>Loading...</p>}
            {error && <p style={{ marginTop: "20px", color: "red" }}>Error: {error}</p>}

            {/* 모달 */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>해당 지역 날씨 정보</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalData ? (
                        <>
                            <h5>지역 정보</h5>
                            <p>
                                <strong>시:</strong> {modalData.si}
                                <br />
                                <strong>구:</strong> {modalData.gu}
                                <br />
                                <strong>동:</strong> {modalData.dong || "없음"}
                            </p>
                            <h5>날씨 정보</h5>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Category</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modalData.weatherSummary.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.category}</td>
                                            <td>{item.obsrValue}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    ) : (
                        <p>데이터가 없습니다.</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        닫기
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default Apis;
