import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container} from 'react-bootstrap';
import Home from "./components/Home";
import Introduction from './components/Introduction';
import Experience from './components/Experience';
import Contact from './components/Contact';
import Apis from './components/Apis';

const scrollToPage = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" }); // block: "start"로 설정하여 최상단에 스크롤
  } else {
    console.error("Element not found for id:", id);
  }
};

function App() {
  return (
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {/* 고정 네비게이션 */}
      <Navbar bg="dark" variant="dark" expand="lg" fixed="top" style={{ zIndex: 10 }}>
        <Container fluid>
          <Navbar.Brand onClick={() => scrollToPage('page1')}>Hoddy Portfolio</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="me-auto"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                paddingLeft: '20px',
              }}
            >
              <Nav.Link onClick={() => scrollToPage('page1')}>Home</Nav.Link>
              <Nav.Link onClick={() => scrollToPage('page2')}>Introduction</Nav.Link>
              <Nav.Link onClick={() => scrollToPage('page3')}>Experience</Nav.Link>
              <Nav.Link onClick={() => scrollToPage('page4')}>ContactUs</Nav.Link>
              <Nav.Link onClick={() => scrollToPage('page5')}>Apis</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div
        style={{
          height: '100vh',
          overflowY: 'scroll',
          scrollSnapType: 'y mandatory',
          scrollBehavior: 'smooth',
        }}
      >
        <Home />
        <Introduction />
        <Experience />
        <Contact />
        <Apis />
      </div>
    </div>
  );
}

export default App;
