import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Introduction() {
    return (
        <Container
            fluid
            id="page2"
            style={{
                height: "100vh",
                background: "linear-gradient(to right, #00c6ff, #0072ff)",
                scrollSnapAlign: "start",
                paddingTop: "56px",
            }}
        >
            <Row style={{ height: "100%" }}>
                {/* 왼쪽 패널 */}
                <Col
                    xs={4}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRight: "2px solid white",
                    }}
                >
                    <div style={{ textAlign: "center" }}>
                        <img
                            src="/people.png" // 프로필 사진 URL
                            alt="people"
                            style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%",
                                marginBottom: "20px",
                            }}
                        />
                        <h2 style={{ color: "white" }}>저는 성과를 이루고 싶은 개발자입니다</h2>
                    </div>
                </Col>

                {/* 오른쪽 패널 */}
                <Col
                    xs={8}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        paddingLeft: "30px",
                    }}
                >
                    <div>
                        <h2 style={{ fontWeight: "bold", fontSize: "2.5rem", color: "white" }}>
                            저를 소개합니다
                        </h2>
                        <p style={{ color: "white", fontSize: "1.2rem", lineHeight: "1.6" }}>
                            안녕하세요! 저는 4년차 백엔드 개발자 이호성입니다. <br />저는 협업하며 결과물을 창출해내는 것을 좋아하고,<br />
                            여러가지 개발 방식 또는 해보지 않은 것에 대해 알아가는 것을 즐기는 사람입니다.<br />
                            아직 부족하지만 열정이 식지않게 뛰고 있고, 계속 앞으로 뛰어가려 합니다.
                        </p>
                        <br />
                        <h2 style={{ fontWeight: "bold", fontSize: "2rem", color: "white" }}>
                            Skills
                        </h2>
                        <div
                            style={{
                                display: "flex", // 수평 정렬
                                flexWrap: "wrap", // 화면 크기에 따라 자동 줄바꿈
                                gap: "20px", // 각 항목 사이의 간격
                                alignItems: "center", // 이미지와 텍스트 수직 정렬
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ textAlign: "center" }}>
                                <img
                                    src="/java.jpg" // Java 관련 아이콘 URL
                                    alt="Java"
                                    style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                                />
                                <div style={{ color: "white", marginTop: "10px" }}>Java</div>
                            </div>

                            <div style={{ textAlign: "center" }}>
                                <img
                                    src="/jpa.png" // JPA 관련 아이콘 URL
                                    alt="JPA"
                                    style={{ width: "80px", height: "80px" }}
                                />
                                <div style={{ color: "white", marginTop: "10px" }}>JPA</div>
                            </div>

                            <div style={{ textAlign: "center" }}>
                                <img
                                    src="/mysql.jpg" // MySQL 관련 아이콘 URL
                                    alt="MySQL"
                                    style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                                />
                                <div style={{ color: "white", marginTop: "10px" }}>MySQL</div>
                            </div>

                            <div style={{ textAlign: "center" }}>
                                <img
                                    src="/springboot.png" // SpringBoot 관련 아이콘 URL
                                    alt="SpringBoot"
                                    style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                                />
                                <div style={{ color: "white", marginTop: "10px" }}>SpringBoot</div>
                            </div>

                            <div style={{ textAlign: "center" }}>
                                <img
                                    src="/firebase.png" // Firebase 관련 아이콘 URL
                                    alt="Firebase"
                                    style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                                />
                                <div style={{ color: "white", marginTop: "10px" }}>Firebase</div>
                            </div>
                        </div>
                        <br />
                        <h2 style={{ fontWeight: "bold", fontSize: "2rem", color: "white" }}>
                            Tools
                        </h2>
                        <div
                            style={{
                                display: "flex", // 수평 정렬
                                flexWrap: "wrap", // 화면 크기에 따라 자동 줄바꿈
                                gap: "20px", // 각 항목 사이의 간격
                                alignItems: "center", // 이미지와 텍스트 수직 정렬
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ textAlign: "center" }}>
                                <img
                                    src="/github.jpg"
                                    alt="GitHub"
                                    style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                                />
                                <div style={{ color: "white", marginTop: "10px" }}>GitHub</div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img
                                    src="/flow.jpg"
                                    alt="Flow"
                                    style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                                />
                                <div style={{ color: "white", marginTop: "10px" }}>Flow</div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img
                                    src="/figma.jpg"
                                    alt="Figma"
                                    style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                                />
                                <div style={{ color: "white", marginTop: "10px" }}>Figma</div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <img
                                    src="/notion.jpg"
                                    alt="notion"
                                    style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                                />
                                <div style={{ color: "white", marginTop: "10px" }}>Notion</div>
                            </div>
                        </div>
                        <br />
                        <h2 style={{ fontWeight: "bold", fontSize: "2rem", color: "white" }}>
                            Links
                        </h2>
                        <div
                            style={{
                                display: "flex", // 수평 정렬
                                flexWrap: "wrap", // 화면 크기에 따라 자동 줄바꿈
                                gap: "20px", // 각 항목 사이의 간격
                                alignItems: "center", // 이미지와 텍스트 수직 정렬
                                marginTop: "10px",
                            }}
                        >
                            <div style={{ textAlign: "center" }}>
                                <a href="https://github.com/hoseong1324" target="_blank">
                                    <img
                                        src="/github.jpg"
                                        alt="GitHub"
                                        style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                                    />
                                </a>
                                <div style={{ color: "white", marginTop: "10px" }}>GitHub</div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <a href='https://www.notion.so/hoddy/Hello-d99fb06ca89645d388b20079de991159' target="_blank">
                                    <img
                                        src="/notion.jpg" 
                                        alt="notion"
                                        style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                                    />
                                </a>
                                <div style={{ color: "white", marginTop: "10px" }}>Notion</div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container >
    );
}

export default Introduction;
